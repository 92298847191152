<template>
  <div v-if="!isLoading" class="p-3">
    <div class="row g-5" v-if="me">
      <div class="col-12 col-lg-6">
        <div class="mb-5 d-flex justify-content-between align-items-center">
          <h5 class="mb-0 py-2">Account Details</h5>
          <button
            class="btn btn-sm btn-light border border-dark ms-auto me-3"
            v-if="checkFormDirty('account')"
            @click="populateDetails({ ...me }, 'account')"
            title="Reset"
          >
            <i class="fas fa-sync-alt" />
          </button>
          <button
            class="btn btn-sm btn-secondary fw-medium px-3"
            v-if="checkFormDirty('account')"
            @click="save('account')"
          >
            <i class="fas fa-save me-1" />
            SAVE
          </button>
        </div>
        <div class="mb-4">
          <label for="email" class="form-label">Email address</label>
          <div class="form-control bg-disabled">
            {{ models.account.email }}
          </div>
        </div>
        <div class="mb-4">
          <label for="name" class="form-label">
            Name<span class="text-danger">*</span>
          </label>
          <input
            type="text"
            class="form-control text-uppercase"
            id="name"
            v-model="models.account.name"
            @change="models.account.name = models.account.name.toUpperCase()"
            :class="{ 'is-invalid': errors.account.name }"
          />
        </div>
        <div class="pt-5">
          <button
            class="btn btn-dark bg-purple-dark"
            @click="
              () => {
                populateDetails({ ...me });

                showPasswordModal = true;
              }
            "
          >
            Click here to change password
            <i class="fas fa-lock ms-2" />
          </button>
        </div>
      </div>
      <hr class="d-lg-none" />
      <div class="col-12 col-lg-6">
        <div class="mb-5 d-flex justify-content-between align-items-center">
          <h5 class="mb-0 py-2">Contact Details</h5>
          <button
            class="btn btn-sm btn-light border border-dark ms-auto me-3"
            v-if="checkFormDirty('contact')"
            @click="populateDetails({ ...me }, 'contact')"
            title="Reset"
          >
            <i class="fas fa-sync-alt" />
          </button>
          <button
            class="btn btn-sm btn-secondary fw-medium px-3"
            v-if="checkFormDirty('contact')"
            @click="save('contact')"
          >
            <i class="fas fa-save me-1" />
            SAVE
          </button>
        </div>
        <div class="mb-4">
          <label for="phone" class="form-label">
            Phone<span class="text-danger">*</span>
          </label>
          <input
            type="tel"
            class="form-control"
            id="phone"
            v-model="models.contact.phone"
            @input="
              models.contact.phone = models.contact.phone.replace(/[^\d,]/g, '')
            "
            :class="{ 'is-invalid': errors.contact.phone }"
          />
        </div>
        <div class="mb-4">
          <label for="address1" class="form-label">Address</label>
          <input
            type="text"
            class="form-control mb-3"
            id="address1"
            v-model="models.contact.address1"
            :class="{ 'is-invalid': errors.contact.address1 }"
          />
          <input
            type="text"
            class="form-control"
            id="address2"
            v-model="models.contact.address2"
            :class="{ 'is-invalid': errors.contact.address2 }"
          />
        </div>
        <div class="row">
          <div class="mb-4 col-12 col-sm-6">
            <label for="postcode" class="form-label">Postcode</label>
            <input
              type="text"
              class="form-control"
              id="postcode"
              v-model="models.contact.postcode"
              @input="
                models.contact.postcode = models.contact.postcode.replace(
                  /[^\d,]/g,
                  ''
                )
              "
              :class="{ 'is-invalid': errors.contact.postcode }"
            />
          </div>
          <div class="mb-4 col-12 col-sm-6">
            <label for="phone" class="form-label">City</label>
            <input
              type="text"
              class="form-control"
              id="city"
              v-model="models.contact.city"
              :class="{ 'is-invalid': errors.contact.city }"
            />
          </div>
        </div>
        <div class="row">
          <div class="mb-4 col-12 col-sm-6">
            <label for="state" class="form-label">State</label>
            <select
              class="form-select"
              v-model="models.contact.state"
              :class="{ 'is-invalid': errors.contact.state }"
            >
              <option :value="null">-- Select state</option>
              <option :value="state" v-for="state in states" :key="state">
                {{ state }}
              </option>
            </select>
          </div>
          <div class="mb-4 col-12 col-sm-6">
            <label for="country" class="form-label">Country</label>
            <input
              type="text"
              class="form-control"
              id="country"
              readonly
              v-model="models.contact.country"
              :class="{ 'is-invalid': errors.contact.country }"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="py-5 text-muted text-center" v-else>No data available</div>
    <change-password-modal
      v-if="showPasswordModal"
      :email="me.email"
      @close="showPasswordModal = false"
    />
  </div>
  <loading-spinner class="position-relative bg-light py-5" v-else>
    Loading user list
  </loading-spinner>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner";
import ChangePasswordModal from "@/components/Modals/ChangePassword";
import States from "@/utils/states";
import Swal from "sweetalert2";

export default {
  components: { LoadingSpinner, ChangePasswordModal },
  data() {
    return {
      isLoading: false,
      states: States,
      me: null,
      showPasswordModal: false,
      models: {
        account: {
          email: null,
          name: null,
        },
        contact: {
          phone: null,
          address1: null,
          address2: null,
          postcode: null,
          city: null,
          state: null,
          country: "Malaysia",
        },
      },
      errors: {
        account: {
          email: false,
          name: false,
        },
        contact: {
          phone: false,
          address1: false,
          address2: false,
          postcode: false,
          city: false,
          state: false,
          country: false,
        },
      },
    };
  },
  methods: {
    save(type) {
      let body = this.models[type];

      for (const key in body) {
        if (Object.hasOwnProperty.call(body, key)) {
          const element = body[key];

          if (key == "email") {
            this.errors[type][key] = !this.Helper.validateEmail(element);
          } else if (key == "phone") {
            this.errors[type][key] = !this.Helper.validateTel(element);
          } else if (key == "postcode" && element && element.length > 0) {
            this.errors[type][key] = !this.Helper.validatePostcode(element);
          } else {
            this.errors[type][key] = false;
          }
        }
      }

      let invalidForm = Object.values(this.errors[type]).some(
        (error) => error == true
      );

      if (invalidForm) {
        return new Swal("", "Invalid form submitted", "error");
      } else {
        this.isLoading = true;

        this.API.put(`users/${this.me._id}`, body)
          .then((retVal) => {
            this.me = { ...retVal.data };

            this.populateDetails(retVal.data);

            return new Swal("", "Data updated", "success");
          })
          .catch((error) => {
            return new Swal("", "Invalid form submitted", "error");
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    checkFormDirty(type) {
      let result = false;

      for (const key in this.models[type]) {
        if (Object.hasOwnProperty.call(this.models[type], key)) {
          const model = this.models[type][key];

          if (model != this.me[key]) {
            result = true;
          }
        }
      }

      return result;
    },
    getMe() {
      this.isLoading = true;

      this.API.get("users/me")
        .then((retVal) => {
          this.me = { ...retVal.data };

          this.populateDetails(retVal.data);
        })
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    populateDetails(data, type) {
      if (type) {
        for (const key in this.models[type]) {
          if (Object.hasOwnProperty.call(this.models[type], key)) {
            this.models[type][key] = data[key];
          }
        }
      } else {
        for (const groupKey in this.models) {
          if (Object.hasOwnProperty.call(this.models, groupKey)) {
            for (const key in this.models[groupKey]) {
              if (Object.hasOwnProperty.call(this.models[groupKey], key)) {
                this.models[groupKey][key] = data[key];
              }
            }
          }
        }
      }
    },
  },
  mounted() {
    this.getMe();
  },
};
</script>