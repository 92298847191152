<template>
  <div id="custom-modal" class="custom-modal modal-fullscreen bg-dark">
    <div class="custom-modal-body modal-body-fixed shadow position-relative">
      <div class="p-3 bg-primary lead text-center fw-medium text-light">
        Change Password
      </div>
      <div class="px-3 pb-3 pt-5">
        <div class="mb-4">
          <label for="email" class="form-label">Email address</label>
          <input
            type="email"
            class="form-control"
            id="email"
            readonly
            v-model="email"
          />
        </div>
        <div class="mb-4">
          <label for="changePassword" class="form-label">
            Current Password
          </label>
          <input
            type="password"
            class="form-control"
            id="changePassword"
            v-model="currentPassword"
            :class="{ 'is-invalid': errors.currentPassword }"
          />
        </div>
        <div class="mb-4">
          <label for="newPassword" class="form-label">New Password</label>
          <input
            type="password"
            class="form-control"
            id="newPassword"
            v-model="newPassword"
            :class="{ 'is-invalid': errors.newPassword }"
          />
          <small class="invalid-feedback">Invalid password format</small>
        </div>
        <div class="mb-4">
          <label for="confirmPassword" class="form-label">
            Confirm Password
          </label>
          <input
            type="password"
            class="form-control"
            id="confirmPassword"
            v-model="confirmPassword"
            :class="{ 'is-invalid': errors.confirmPassword }"
          />
          <small class="invalid-feedback">Password does not match</small>
        </div>
        <ul class="small text-muted mb-4">
          <li>Password must be at least 7 characters long</li>
          <li>Must contains at least 1 number</li>
          <li>Must contains at least 1 letter</li>
        </ul>
        <div class="d-flex justify-content-between align-items-center">
          <button class="btn btn-light shadow" @click="closePreview()">
            Cancel
          </button>
          <button class="btn btn-primary" @click="submitChange()">
            <i class="fas fa-save me-2" />
            Submit
          </button>
        </div>
      </div>
      <loading-spinner class="text-light position-absolute" v-if="isLoading">
        Processing your request...
      </loading-spinner>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
  props: ["email"],
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      isLoading: false,
      currentPassword: null,
      newPassword: null,
      confirmPassword: null,
      errors: {
        currentPassword: false,
        newPassword: false,
        confirmPassword: false,
      },
    };
  },
  watch: {
    currentPassword() {
      this.errors.currentPassword = !this.Helper.validatePassword(
        this.currentPassword
      );
    },
    newPassword() {
      this.errors.newPassword = !this.Helper.validatePassword(this.newPassword);

      if (this.confirmPassword) {
        this.errors.confirmPassword =
          !this.Helper.validatePassword(this.confirmPassword) ||
          this.confirmPassword != this.currentPassword;
      }
    },
    confirmPassword() {
      this.errors.confirmPassword =
        !this.Helper.validatePassword(this.confirmPassword) ||
        this.confirmPassword != this.newPassword;
    },
  },
  computed: {
    formInvalid() {
      if (
        !this.currentPassword ||
        !this.newPassword ||
        !this.confirmPassword ||
        this.currentPassword.length < 7 ||
        this.newPassword.length < 7 ||
        this.confirmPassword.length < 7
      ) {
        return true;
      } else if (Object.values(this.errors).some((error) => error === true)) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    closePreview(e) {
      this.$nextTick(() => {
        this.$emit("close", e);
      });
    },
    async submitChange() {
      if (this.formInvalid) {
        Swal.fire("", "Invalid password format", "error");
      } else {
        this.isLoading = true;

        let body = {
          identifier: this.email,
          newPassword: this.newPassword,
          password: this.currentPassword,
        };

        try {
          await this.API.post("password/reset", body);

          Swal.fire(
            "Password successfully changed",
            "Please login again using your new password",
            "success"
          ).then(() => {
            localStorage.clear();

            this.closePreview();

            this.$router.push({
              name: "SignIn",
            });
          });
        } catch (err) {
          Swal.fire(
            "Opps. An error occured",
            err.response.data.message[0].messages[0].message,
            "error"
          );
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
  mounted() {
    document.getElementsByTagName("body")[0].classList.add("overflow-hidden");
  },
  beforeDestroy() {
    this.closePreview();

    document
      .getElementsByTagName("body")[0]
      .classList.remove("overflow-hidden");
  },
};
</script>